import React from 'react';

const KlaviyoTest = () => {
  return (
    <div>
      <h1>Klaviyo Test</h1>
    </div>
  );
};

export default KlaviyoTest;
